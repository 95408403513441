import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend'; // pour charger les fichiers de traduction

const language = localStorage.getItem('siris_language') || 'fr';

i18n
  .use(HttpBackend) // charge les fichiers de traduction
  .use(initReactI18next) // passe l'instance i18n (i18next) à react-i18next
  .init({
    fallbackLng: 'fr', // langue à utiliser si la traduction en langue actuelle est absente
    lng: language, // langue initiale
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // chemin pour charger les locales
    },
    interpolation: {
      escapeValue: false // réact échappe déjà les valeurs
    }
  });

export default i18n;
