import { cilSettings } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAlert } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { getCurrentMaintenance } from 'src/actions/NotificationActions'

const BannerNotification = () => {

  const [banner, setBanner] = useState([]);

  const fetchBanner = async () => {
    try {
        const response = await getCurrentMaintenance({})
        setBanner(response ?? []);
    } catch (error) {
      console.error('Erreur lors de la récupération des bandeaux de maintenance', error);
    }
  };


  useEffect(() => {
    fetchBanner();
    const interval = setInterval(() => {
        fetchBanner();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (banner?.length === 0) {
    return null;
  }

  return (
    <div className="banner-container">
      {banner?.map((bandeau) => (
        <CAlert key={bandeau.idmaintenance_history+''+(new Date().getTime())} color="danger" dismissible className="d-flex align-items-center banner-item">
            <CIcon icon={cilSettings} className="flex-shrink-0 me-2" width={24} height={24} />
            <div dangerouslySetInnerHTML={{ __html: bandeau.message }}></div>
        </CAlert>
      ))}
    </div>
  );
};

export default BannerNotification;