import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { getClientFromLocalStorage, getTokenFromLocalStorage, getUserFromLocalStorage } from 'src/common/common';
import jwt_decode from "jwt-decode";
import { logout, refreshToken } from 'src/actions/AuthentificationActions';
import { ROLE_CLIENT } from 'src/constants/app';

const ClientContext = createContext();

export function useClientContext() {
  return useContext(ClientContext);
}



const ClientProvider = ({ children }) => {

  const [clientInfo, setClientInfo] = useState(null);
  const [token, setToken] = useState(() => getTokenFromLocalStorage());

  const isInitialized = useRef(null)
  const isRefreshing = useRef(null)
  const user = getUserFromLocalStorage()
  const clientStocked = getClientFromLocalStorage()
  const isAdmin = clientStocked?.role?.label === ROLE_CLIENT.ADMINISTRATEUR


  useEffect(() => {

    const handleStorage = () => {
      setToken(getTokenFromLocalStorage())
    }

    window.addEventListener('storage', handleStorage)

    isInitialized.current = true

    return () => {
      window.removeEventListener('storage', handleStorage)
    }
  }, [])



  useEffect(() => {

    if (!isInitialized.current) return

    let timeOutID;


    if (token) {


      if (token) {
        let decodedToken = jwt_decode(token);

        const { exp } = decodedToken;

        const now = Date.now().valueOf() / 1000;

        if (user) {

          if (exp < now) {

            logout(false)
          }

          // const timeBeforeExpiration = (exp - now) - 30; // 30s   before expiration
          const timeBeforeExpiration = (exp - now) - 60; // one min before expiration

          timeOutID = setTimeout(async () => {
            if (isRefreshing.current) return

            isRefreshing.current = true
            try {
              await refreshToken()
            } catch {
              console.log('ato erreur userHooks')
            } finally {
              isRefreshing.current = false
            }
          }, timeBeforeExpiration * 1000);

        }
      }

    }

    return () => {
      clearTimeout(timeOutID);
    }

  }, [token])


  return (
    <ClientContext.Provider value={{ clientInfo, setClientInfo, isAdmin }}>
      {children}
    </ClientContext.Provider>
  );

}
ClientProvider.propTypes = {
  children: PropTypes.node
}

export default ClientProvider
