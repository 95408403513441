import { createSlice } from "@reduxjs/toolkit";

const sidebarShowSlice = createSlice({
  name: 'sidebarShow',
  initialState: {
    sidebarUnfoldable: false,
    sidebarShow: true,
  },
  reducers: {
    setSidebarShow: (state, action) => ({ ...state, sidebarShow: action.payload }),
    setSideBarUnfoldable: (state, action) => ({ ...state, sidebarUnfoldable: action.payload })

  }
})


export const { setSidebarShow, setSideBarUnfoldable } = sidebarShowSlice.actions;

export default sidebarShowSlice.reducer;
