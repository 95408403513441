import { createSlice } from '@reduxjs/toolkit';

const pageConfigSlice = createSlice({
  name: 'pageConfig',
  initialState: 10,
  reducers: {
    setPageConfig: (state, action) => (action.payload),
    resetPageState: () => 10,
  },
});

export const { setPageConfig, resetPageState } = pageConfigSlice.actions;

export default pageConfigSlice.reducer;
