import HttpClient from 'src/common/HttpClient'
import { store } from 'src/app/store'

export const getClients = async (payload) => {
  const params = {
    url: 'compte-clients',
    data: { ...payload, limit: store.getState().pageConfig, page: payload.page ?? 0 }
  }

  const response = await HttpClient.get(params, true)
  return response
}


export const getClient = async (payload) => {
  const id = payload.id
  delete payload.id
  const params = {
    url: 'compte-clients/' + id,
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}


export const postClient = async (payload) => {
  const params = {
    url: 'compte-clients',
    data: { ...payload }
  }

  const response = await HttpClient.post(params, true)
  return response
}

export const sendInvitationClient = async (payload) => {
  const params = {
    url: 'invitation-client',
    data: { ...payload }
  }

  const response = await HttpClient.post(params, true)
  return response
}

export const updateClient = async (payload) => {

  const iduser = payload.id
  delete payload.id

  const params = {
    url: 'compte-clients' + (iduser ? '/' + iduser : ''),
    data: { ...payload }
  }

  const response = await HttpClient.putWithFile(params, true)
  return response
}

export const updatePersonalInfo = async (payload) => {
  const params = {
    url: 'compte-clients/personal-info',
    data: { ...payload }
  }

  const response = await HttpClient.putWithFile(params, true)
  return response
}

export const deleteClient = async (payload) => {
  const params = {
    url: 'compte-clients/' + payload.id,
    data: { ...payload }
  }

  const response = await HttpClient.delete(params, true)
  return response
}

export const getClientByUserId = async (payload) => {
  const id = payload.id
  delete payload.id
  const params = {
    url: 'compte-clients-by-user/' + id,
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getClientInCompany = async (payload) => {

  const params = {
    url: 'compte-clients-by-company',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}
export const getResponsableInCompany = async (payload) => {

  const params = {
    url: 'responsables-by-company',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getMembreEquipeInCompany = async (payload) => {

  const params = {
    url: 'membres-by-company',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getAdminClientInCompany = async (payload) => {

  const params = {
    url: 'admin-by-company',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getTeamByProcess = async (payload) => {

  const params = {
    url: 'team-by-process',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getAbonnement = async (payload) => {

  const params = {
    url: 'compte-clients-abonnement',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getUtilisateursByCompany = async (payload) => {

  const params = {
    url: 'compte-clients-utilisateurs',
    data: { ...payload, limit: store.getState().pageConfig, page: payload.page ?? 0 }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getCompany = async (payload) => {

  const params = {
    url: 'compte-client/entreprise',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getDepartementCompany = async (payload) => {

  const params = {
    url: 'compte-client/entreprise/departements',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const getUtilisateur = async (payload) => {
  const id = payload.id
  delete payload.id
  const params = {
    url: 'compte-client/utilisateur/' + id,
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}

export const updateNotifyClient = async (payload) => {
 
  const params = {
    url: 'compte-client-notification',
    data: payload
  }

  const response = await HttpClient.put(params, true)
  return response
}
