import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const DefineClientPassword = React.lazy(() => import('./views/pages/passwordRecovery/DefineClientPassword'))
const RequestPasswordRecovery = React.lazy(() => import('./views/pages/forgotPassword/RequestPasswordRecovery'))
const ResetPassword = React.lazy(() => import('./views/pages/forgotPassword/ResetPassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Connexion" element={<Login />} />
            <Route exact path="/my-password/:key" name="Mot de passe client" element={<DefineClientPassword />} />
            {/* <Route exact path="/register" name="Inscription" element={<Register />} /> */}
            <Route exact path="request-password-recovery" name="Récupération de mot de passe" element={<RequestPasswordRecovery />} />
            <Route exact path="reset-password/:encodedKey" name="Reinitialisation de mot de passe" element={<ResetPassword />} />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Accueil" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App

// http://localhost:3000/#/reset-password/encodedKey=U2FsdGVkX180ISjKmoXiOVNwtOf4tEchAUOmqonpa5UCziXAGv0Uk5Tb3twyBHO3zloXpIYxU0cNxjZH/D2pA8UQ/Q9+226PsUxn4hE6xnI=
