import { store } from 'src/app/store'
import HttpClient from 'src/common/HttpClient'

export const getCurrentMaintenance = async (payload) => {
  const params = {
    url: 'current-maintenance-history',
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}
