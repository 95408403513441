import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import {
  persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import pageConfigReducer from '../features/pageConfig/pageConfigSlice';
import sideBarShowReducer from '../features/sideBarShow/sideBarShowSlice';

const reducers = combineReducers({
  pageConfig: pageConfigReducer,
  sidebarShow: sideBarShowReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // Vous pouvez également inclure une liste blanche ou une liste noire de reducers ici
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    {
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }
  ),
});

export const persistor = persistStore(store);
