import { NotificationManager } from 'react-notifications'
import { MODULES, PROPERTY_NAME_ACCESS } from 'src/constants/app';
import React from 'react';
import DOMPurify from 'dompurify';

import {
  CCol,
  CListGroup,
  CListGroupItem,

} from '@coreui/react'

import Cookies from 'js-cookie';
import dayjs from 'dayjs';
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const encryptedPrefix = 'encrypted_'

export function storeTokenInLocalStorage(token) {
  if (token) {
    window.dispatchEvent(new Event('storage'))
    localStorage.setItem('tokenSiris', encryptedPrefix + encrypt(JSON.stringify(token)))
  }

}

export function storeRefreshTokenInLocalStorage(token) {
  if (token)

    localStorage.setItem('refreshTokenSiris', encryptedPrefix + encrypt(JSON.stringify(token)))
}

export function getTokenFromLocalStorage() {
  const cryptedData = localStorage.getItem('tokenSiris')
  let decryptedData = null
  if (cryptedData?.includes(encryptedPrefix)) {
    const cryptedDataWithoutPrefix = cryptedData.replace(encryptedPrefix, '').trim()
    try {
      if (cryptedDataWithoutPrefix) decryptedData = JSON.parse(decrypt(cryptedDataWithoutPrefix))

    } catch (error) { return null }
  }
  return decryptedData
}

export function storeUserInLocalStorage(user) {
  if (user)
    localStorage.setItem('userSiris', encryptedPrefix + encrypt(JSON.stringify(user)))
}

export function getUserFromLocalStorage() {
  const cryptedData = localStorage.getItem('userSiris')
  let decryptedData = null
  if (cryptedData?.includes(encryptedPrefix)) {
    const cryptedDataWithoutPrefix = cryptedData.replace(encryptedPrefix, '').trim()
    try {
      if (cryptedDataWithoutPrefix) decryptedData = JSON.parse(decrypt(cryptedDataWithoutPrefix))
    } catch (error) { return null }

  }
  return decryptedData
}

export function hasSpace(space) {
  const user = getUserFromLocalStorage()
  return user?.space?.label === space
}

export function storeClientInLocalStorage(user) {
  if (user)
    localStorage.setItem('clientSiris', encryptedPrefix + encrypt(JSON.stringify(user)))
}

export function getClientFromLocalStorage() {
  const cryptedData = localStorage.getItem('clientSiris')
  let decryptedData = null
  if (cryptedData?.includes(encryptedPrefix)) {
    const cryptedDataWithoutPrefix = cryptedData.replace(encryptedPrefix, '').trim()
    try {
      if (cryptedDataWithoutPrefix) decryptedData = JSON.parse(decrypt(cryptedDataWithoutPrefix))
    } catch (error) { return null }
  }
  return decryptedData
}

export function incrementVersion(version) {
  const [major, minor] = version.split('.')

  if (major && !isNaN(major) && minor && !isNaN(minor)) {
    return `${parseInt(major) + 1}.0`
  }

  else if (isNaN(major) && !minor) {
    return `${major}.1`
  }

  else if (isNaN(major) && minor && !isNaN(minor)) {
    return `${major + '.' + (parseInt(minor) + 1)}`
  }

  return `1.0`
}

export function htmlToText(htmlString) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  return doc.body.textContent
}

export function limitTo25Characters(str) {
  if (str.length > 25) {
    return str.substring(0, 25) + '...'
  }
  return str
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getAccess = (propertyName, showError = false) => {
  const client = getClientFromLocalStorage()

  if (client?.role && client?.role[propertyName]) {
    return true
  } else {
    if (showError) {
      const entitiesList = Object.values(PROPERTY_NAME_ACCESS)
      const entity = entitiesList.find(entity => [entity.read, entity.write, entity.delete, entity.create].includes(propertyName))

      NotificationManager.error(entity ? "Accès refusé | " + entity.title : 'Accès refusé', 'Erreur')
    }

    return false
  }
}


export function encrypt(data) {
  const key = process.env.REACT_APP_ENCRYPTION_KEY ?? '1234567890123456'

  var encryptedData = '';
  for (var i = 0; i < data.length; i++) {
    var charCode = data.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    encryptedData += String.fromCharCode(charCode);
  }
  return btoa(encryptedData);
}

export function decrypt(encryptedData) {
  const key = process.env.REACT_APP_ENCRYPTION_KEY ?? '1234567890123456'

  var decryptedData = '';
  encryptedData = atob(encryptedData);
  for (var i = 0; i < encryptedData.length; i++) {
    var charCode = encryptedData.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    decryptedData += String.fromCharCode(charCode);
  }

  return decryptedData;
}

export function hasRole(role) {
  const client = getClientFromLocalStorage()
  return client?.role?.label === role
}

export function isUrl(url) {
  return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(url);
}

export function formatUrl(url) {
  if (!url) return ''
  return `<a href="${url}" target="_blank">${url}</a>`
}

export function labelValueCard(label, value, innerHtml = true) {

  const sanitizedValue = DOMPurify.sanitize(value)

  return (
    <div className="d-flex align-items-center gw-label-value-container mb-2" style={{ gap: '10px' }}>
      <div className="gw-label-infos" style={{ flexBasis: '30%', flexGrow: 0 }}>
        <span style={{ textTransform: 'math-auto' }}>{label}</span>
      </div>
      <div className="gw-value-infos" style={{ flexBasis: '70%', flexGrow: 1 }}>
        {innerHtml ? (
          <div dangerouslySetInnerHTML={{ __html: sanitizedValue }}></div>
        ) : (
          <div>{value}</div>
        )}
      </div>
    </div>
  );
}

export function sanitizeValue(value) {
  return DOMPurify.sanitize(value)
}

export function getFileNameFromUrl(url) {
  const splittedUrl = url?.split('/')
  if (splittedUrl?.length && splittedUrl[splittedUrl.length - 1].includes('pdf')) {
    const fileName = splittedUrl[splittedUrl.length - 1]
    const splitted = fileName.split('.')
    return splitted[0]
  }
  return ''
}

export function handleCLientSubcriptionTypeLabel(type) {
  if (type === MODULES.RISK) {
    return 'Risque'
  } else if (type === MODULES.DOCUMENT) {
    return 'Processus'
  } else if (type === MODULES.HUMAN_RESOURCES) {
    return 'Ressources humaines'
  } else {
    return 'Conformité'
  }
}

export function infoDisplay(label, value) {
  return (
    <div className="info-container">
      <div className="info-label">
        <h6>{label}:</h6>
      </div>
      <div className="info-value">
        <div dangerouslySetInnerHTML={{ __html: value }}></div>
      </div>
    </div>
  );
};

export const formatDate = (dateFormDb) => {
  if (!dateFormDb) return ''
  const parts = dateFormDb.split('-');
  return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
}

export function formatDateTime(inputString) { //format dd/MM/YYYY 09:08
  const date = dayjs(inputString, 'DD/MM/YYYY HH:mm');
  return date.format('YYYY-MM-DDTHH:mm');
}

export function formatDateToString(dateString) { // format 2024-10-15T21:00:00.000Z
  if (!dateString) return ''
  const date = dayjs(dateString);
  return date.format('DD/MM/YYYY');
}

export const DefaultDiagram = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
  <bpmn2:process id="Process_1" isExecutable="false">
    <bpmn2:startEvent id="StartEvent_1"/>
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>`;

// Pour supprimer le cookie contenant le token
export function removeRefreshTokenSirisInCookie() {
  Cookies.remove('refreshTokenSiris', { secure: true, sameSite: 'strict' });
}

export const card = (title, dataList = [], colList) => {
  return (
    <CCol>
      <h5 className='gw-section-title'>{title}</h5>
      <CListGroup>
        {dataList.map((row, index) => {
          return (
            <>
              <CListGroupItem key={row.title + index} className="list-group-action" >
                <div style={{ width: '100%' }}>
                  {colList.map((col) => {
                    return labelValueCard(col.title, row[col.property] ?? '')
                  })}
                </div>

              </CListGroupItem>
            </>
          )
        })
        }
      </CListGroup>
    </CCol>
  )
}

export const generateFileIcon = (filename) => {
  // Récupérer l'extension du fichier
  const fileExtension = filename.split('.').pop().toLowerCase();

  // Tableau de correspondance entre les extensions de fichier et les icônes correspondantes
  const iconMap = {
    'pdf': 'pdf-256.png',
    'jpg': 'image-file-256.png',
    'jpeg': 'image-file-256.png',
    'png': 'image-file-256.png',
    'gif': 'image-file-256.png',
    'bmp': 'image-file-256.png',
    'svg': 'image-file-256.png',
    'mp4': 'video-file-3-256.png',
    'mov': 'video-file-3-256.png',
    'avi': 'video-file-3-256.png',
    'wmv': 'video-file-3-256.png',
    'doc': 'word-3-256.png',
    'docx': 'word-3-256.png',
    'xls': 'exel-256.png',
    'xlsx': 'exel-256.png',
    'ppt': 'powerpoint-3-256.png',
    'pptx': 'powerpoint-3-256.png'
  };

  // Vérifier si l'extension du fichier correspond à une des clés dans l'objet iconMap
  if (fileExtension in iconMap) {
    return process.env.REACT_APP_BASE_URL + '/images/' + iconMap[fileExtension];
  } else {
    // Si l'extension n'est pas trouvée dans iconMap, retourner l'icône par défaut ('file-256.png')
    return process.env.REACT_APP_BASE_URL + '/images/file-256.png';
  }
}

export function gethasRolePermission(row, permissionKey, checkCreatorPermission = false) {

  if (!row || !row?.rolePermission) return false

  if (row.rolePermission.defaultRolePermission[permissionKey] || row.rolePermission.customRolePermisson[permissionKey]) {

    return true
  }

  if (checkCreatorPermission && !!row[`creator_${permissionKey}`] && row.isCreatorConnected) {
    return true
  }
  return false
}

export function decodeHtmlEntities(str) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.documentElement.textContent;
}

export function encryptHex(data) {
  const key = process.env.REACT_APP_ENCRYPTION_KEY ?? '1234567890123456';
  let encryptedData = '';
  for (let i = 0; i < data.length; i++) {
    const charCode = data.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    encryptedData += String.fromCharCode(charCode);
  }
  return toHex(encryptedData);
}

export function decryptHex(encryptedData) {
  const key = process.env.REACT_APP_ENCRYPTION_KEY ?? '1234567890123456';
  let decryptedData = '';
  const decodedData = fromHex(encryptedData);
  for (let i = 0; i < decodedData.length; i++) {
    const charCode = decodedData.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    decryptedData += String.fromCharCode(charCode);
  }
  return decryptedData;
}

function toHex(str) {
  return Array.from(str).map(c => c.charCodeAt(0).toString(16).padStart(2, '0')).join('');
}

function fromHex(hex) {
  return hex.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
}

export function getLastVersion(lastVersion) {
  const newLastVersion = (parseFloat(lastVersion) + 0.1).toFixed(1);
  return newLastVersion;
}

